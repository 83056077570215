import React, { useState } from 'react';
import './App.scss';
import { publicRoutes } from './routes/';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import gridImg from './assets/images/grid.png';

const App = () => {
	const location = useLocation();
	const NAV = [
		{ name: 'Work', slug: 'work' },
		{ name: 'Code', slug: 'code' },
		{ name: 'Design', slug: 'design' },
		{ name: 'Photos', slug: 'photos' },
		{ name: 'Craft', slug: 'craft' },
		{ name: 'Data science', slug: 'data-science' },
		{ name: 'Skill set', slug: 'skill-set' },
	];

	return (
		<div className="App">
			<div className="flex-row gap-sm align-items-center">
				<h3>Abdirahim Arrale</h3>
				<img src="https://abdirahim-cce7d.web.app/images/pb.png" width="60px" />
			</div>

			<p className="mb-3" style={{ maxWidth: '450px' }}>
			Wide range of interests, but i focus on <span className='text-dark'>design engineering</span>. Based in <span className='text-dark'>Oslo, Norway.</span>
			</p>
			<p className='sm mb-1'>Experience</p>
			<ul className="list">
				<li>
					Studied data science, but dropped out to co-found{' '}
					<a href="https://www.mona.rent/" target="_blank" rel="noopener noreferrer">
						<span className="interactive-link">mona</span>
					</a>
				</li>
				<li>
					Designed and co-engineered the first platform for solgt.no.{' '}
					<a
						href="https://www.dn.no/teknologi/solgtno/luxor-capital/arctic-securities/solgtno-har-kjopt-norske-boliger-til-fastpris-for-220-mill-pa-ti-maneder-na-blir-et-amerikansk-hedgefond-med-pa-laget/2-1-1086389"
						target="_blank"
						rel="noopener noreferrer">
						<span className="interactive-link">Managed assets for $22m first year </span>
					</a>
					 with a dev team of 3
				</li>
				<li>
					Co-founded{' '}
					<a href="https://vimomedia.com/" target="_blank" rel="noopener noreferrer">
						<span className="interactive-link">Vimo Media</span>
					</a>
					. Worked with Sony, Ikea, Universal and others
				</li>
			</ul>

			<div className="nav">
				{NAV.map(nav => (
					<Link to={`/${nav.slug}`} key={nav.name}>
						<button className={`btn btn-primary ${location.pathname === '/' + nav.slug || (location.pathname === '/' && nav.slug === 'work') ? 'active' : ''}`}>{nav.name}</button>
					</Link>
				))}
				<div className="nav-fade"></div>
			</div>

			<Routes>
				{publicRoutes.map((route, index) => (
					<Route key={index} path={route.path} exact={Boolean(route.exact)} element={route.component} />
				))}
			</Routes>

			<img src={gridImg} width="60%" className="grid-bg" />
		</div>
	);
};

export default App;
