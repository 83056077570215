// public pages

import Code from "../pages/Code/Code";
import Craft from "../pages/Craft/Craft";
import DataScience from "../pages/DataScience/DataScience";
import Design from "../pages/Design/Design";
import Lance from "../pages/Lance/Lance";
import Photos from "../pages/Photos/Photos";
import Projects from "../pages/Projects/Projects";
import Skills from "../pages/Skills/Skills";


const publicRoutes = [
	{ path: '/', exact: true, component: <Projects /> },
	{ path: '/work', exact: true, component: <Projects /> },
	{ path: '/photos', exact: true, component: <Photos /> },
	{ path: '/craft', exact: true, component: <Craft /> },
	{ path: '/data-science', exact: true, component: <DataScience />},
	{ path: '/code', exact: true, component: <Code />},
	{ path: '/design', exact: true, component: <Design />},
	{ path: '/skill-set', exact: true, component: <Skills />},
	{ path: '/lance', exact: true, component: <Lance />},
];

export { publicRoutes };
