import React from 'react';
import './Skills.scss';
import skillData from '../../data/skills.json';

function Skills() {
	return (
		<div className="flex-row r-sm fade-up" style={{ gap: '50px' }}>
			{skillData.map(skill => (
				<div className="skill" key={skill.name}>
					<p className="sm">{skill.name}</p>
					<ul>
						{skill.skills.map(s => (
							<li key={s}>{s}</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
}

export default Skills;
